@tailwind base;
@tailwind components;
@tailwind utilities;


.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.leaflet-popup {
  @apply pb-6
}

.cluster-marker {
  @apply bg-secondary text-primary rounded-full p-3 w-4 h-4 flex justify-center items-center;
}

.cluster-marker-alarm-low {
  @apply bg-yellow-400 text-primary rounded-full p-3 w-4 h-4 flex justify-center items-center;
}

.cluster-marker-alarm-medium {
  @apply bg-orange-500 text-primary rounded-full p-3 w-4 h-4 flex justify-center items-center;
}

.cluster-marker-alarm-high {
  @apply bg-red-600 text-primary rounded-full p-3 w-4 h-4 flex justify-center items-center;
}
